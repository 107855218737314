import { AnimatePresence } from "framer-motion";
import React, { useState } from "react";

import { motion } from "framer-motion";

export default function BottomNav() {
  const [explore, setExplore] = useState(false);
  const [shows, setShows] = useState(false);
  const [videos, setVideos] = useState(false);
  const [about, setAbout] = useState(false);
  function resetAll() {
    setExplore(false);
    setShows(false);
    setVideos(false);
    setAbout(false);
  }
  return (
    <div className>
      <div className="fixed bottom-[72px] left-0  w-full md:hidden z-40">
        <BottomNavShows/>
</div>
      <div className="fixed bottom-0 left-0   backdrop__filter w-full md:hidden z-40">
        <div className="flex justify-center">
          <a href="#explore" className="p-6 active:bg-white ">
            <AnimatePresence>
              {explore ? (
                <motion.div className="opacity-100">
                  <img
                    src="/assets/explore-active.svg"
                    className="h-6 w-full"
                    alt="Explore"
                  />
                  <div className="mx-auto w-4 h-0.5 bg-darkorangeg relative top-1 " />
                </motion.div>
              ) : (
                <motion.div
                  onClick={() => {
                    resetAll();
                    setExplore(true);
                  }}
                  className="opacity-100"
                >
                  <img
                    src="/assets/explore.svg"
                    className="h-6 w-6"
                    alt="Explore"
                    srcSet
                  />
                </motion.div>
              )}
            </AnimatePresence>
          </a>
          <a href="#shows" className="p-6">
            <AnimatePresence>
              {shows ? (
                <motion.div className="opacity-100 relative">
                  <img
                    src="/assets/shows-active.svg"
                    className="h-6 w-6"
                    alt="shows"
                  />
                  <div className="mx-auto w-4 h-0.5 bg-darkorangeg relative top-1 " />
                </motion.div>
              ) : (
                <motion.div
                  className="opacity-100 relative"
                  onClick={() => {
                    resetAll();
                    setShows(true);
                  }}
                >
                  <div className="h-3 w-3 rounded-full bg-red-500 absolute -top-[4px] -right-[4px] text-red-500  text-center text-[12px] font-extrabold">
                    4
                  </div>
                  <img
                    src="/assets/shows.svg"
                    className="h-6 w-6"
                    alt="shows"
                    srcSet
                  />
                </motion.div>
              )}
            </AnimatePresence>
          </a>
          <div className="p-[10px]">
            <a
              href="#header"
              className="h-12 w-12 bg-blueg text-white flex justify-center justify-items-center items-center font__mochiy-pop-one opacity-100"
              onClick={() => resetAll()}
            >
              <h1>G K</h1>
            </a>
          </div>
          <a href="#videos" className="p-6">
            {videos ? (
              <motion.div>
                <img
                  src="/assets/videos-active.svg"
                  className="h-6 w-6"
                  alt="videos"
                />
                <div className="mx-auto w-4 h-0.5 bg-darkorangeg relative top-1 " />
              </motion.div>
            ) : (
              <motion.div
                onClick={() => {
                  resetAll();
                  setVideos(true);
                }}
              >
                <img
                  src="/assets/videos.svg"
                  className="h-6 w-6"
                  alt="videos"
                  srcSet
                />
              </motion.div>
            )}
          </a>
          <a href="#footer" className="p-6">
            <AnimatePresence>
              {about ? (
                <motion.div>
                  <img
                    src="/assets/person-active.svg"
                    className="h-6 w-6"
                    alt="about"
                  />
                  <div className="mx-auto w-4 h-0.5 bg-darkorangeg relative top-1 " />
                </motion.div>
              ) : (
                <motion.div
                  onClick={() => {
                    resetAll();
                    setAbout(true);
                  }}
                >
                  <img
                    src="/assets/person.svg"
                    className="h-6 w-6"
                    alt="about"
                    srcSet
                  />
                </motion.div>
              )}
            </AnimatePresence>
          </a>
        </div>
      </div>
    </div>
  );
}



const BottomNavShows = () => {
  return (
    <>
      <div className="flex justify-start pt-4  w-full md:mx-2">
        <a href="#shows" className="bg-pinkg border-2 border-pinkg text-white w-full text-center pl-[4%] pr-[4.4%] py-2 rounded-t-xl font-extrabold  hover:border-[#ffdb15] hover:border-2 transition-all ">
          View All Shows
          <span className="  absolute pl-2 pt-[3px] ">
            {/* {hoverBook ?  */}
            <img src="/assets/arrow red.gif" className="h-4 w-4 " alt="" />
            {/* //  <img src="/assets/arrow red.gif" className="h-4 w-4  " alt="" />} */}
          </span>
        </a>
      </div>
    </>
  );
};