
export default function TikTok() {
  return (
    <div className="md:bg-grayg">
      <div className="mt-[52px] md:container  md:mx-auto md:mt-[132px] ">
        <img
          src="/assets/party-prop.png"
          className="w-full bg-cover -mb-8 z-0 md:hidden"
          alt="Book Show"
        />
        {/* <img
          src="/assets/tik-tok-bg-mobile.svg"
          className="w-full bg-cover -mb-0.5 md:hidden"
          alt="Book Show"
        /> */}
        <div className="mx-auto pt-20 pb-10 px-10 bg-grayg md:mx-52">
          {/* <img
            src="/assets/tik-tok.svg"
            className="mx-auto md:mx-0 px-5 md:px-0 h-full bg-cover md:h-[360px] md:w-[609px]"
            alt="Book Show"
          /> */}
          <div className="mx-auto relative h-28 ">
            <img
              src="/assets/bomb.svg"
              className="mx-auto sm:ml-[20%] lg:ml-[20%] h-full bg-cover w-40 lg:h-52 lg:w-full absolute -top-2  md:left-40 2xl:left-12"
              alt="Book Show"
            />
            <div id="shows" className="absolute mt-14"></div>
          </div>
          <div className="text-blueg py-4 md:pt-0">
            {/* <div className="px-5 md:text-xl md:w-1/2">
              We've put together another humour just for you. Waiting for you on
              the stage! We bring you new
            </div> */}
              {/* <span className="font-bold"> APPEARANCES and SHOWS.</span> */}
          </div>
          {/* <div className="h-0.5 bg-blueg mx-5 md:w-1/2"></div> */}
        </div>
      </div>
    </div>
  );
}
