
// render(html`<${Footer} />`, document.body);
export default function CoverEyes() {
  return (
    <div className="bg-blueg my-2 md:md:container md:mx-auto md:pb-20">
      <div className="md:mx-52">
        <div className="p-2"></div>
        <div className="w-screen md:w-[118%] 2xl:w-[110%] px-10 relative">
          <img
            src="/assets/cover-eyes-desktop-bg.png"
            className=" w-full pl-1 md:pl-0 md:block hidden absolute top-24 left-[-7%] 2xl:left-[-5%]"
            alt="Book Show"
          />
          <div className="flex flex-row items-center justify-center md:justify-start md:items-start relative md:left-[-10%] 2xl:left-[-5%]">
            <div className="flex flex-col items-center justify-around md:h-[520px] md:px-16">
              <img
                src="/assets/gaurav-eyes.png"
                className=" w-[130px] md:w-[159px]"
                alt="Book Show"
              />
              <img
                src="/assets/deaf-face.png"
                className="w-[70px] md:w-32"
                alt="Book Show"
              />
              <img
                src="/assets/gaurav-face.png"
                className="w-[64px] md:w-[109px]"
                alt="Book Show"
              />
            </div>
            <div className="w-full md:h-full md:w-auto">
              <img
                src="/assets/cover-eyes-text.svg"
                className="h-full pl-1 md:pl-0 md:hidden"
                alt="Book Show"
              />
              <img
                src="/assets/cover-eyes-desktop.svg"
                className="h-full pl-1 md:pl-0 md:h-[520px] md:w-[510px] md:block hidden"
                alt="Book Show"
              />
            </div>
            <div className="absolute right-0 bottom-4 md:hidden">
              <img
                src="/assets/cover-eye.svg"
                className="w-28"
                alt="Book Show"
              />
            </div>
          </div>
        </div>
        <div className="p-12" id="explore"></div>
      </div>
    </div>
  );
}