
// render(html`<${Footer} />`, document.body);
export default function FooterAbout() {
  return (
    <div className="px-10 pt-20 md:pt-24">
      <ul className="list-inline  justify-between py-4   hidden md:flex">
        <div></div>
        <li className="list-inline-item mx-2 md:-mr-px">
          <a href="#header">
            <img src="/assets/back-to-top.svg" className="w-8 h-8" alt="back to top" />
          </a>
        </li>
      </ul>
      <div className="flex flex-col md:flex-row">
        <div className="bg-grayg  md:w-1/2">
          <div className="flex">
            <img src="/assets/gaurav-face.svg" class="-ml-1" alt="" srcset="" />
            <div className="text-[25px] pl-2 font__mochiy-pop-one text-blueg">Gaurav Kapoor</div>
          </div>
          <div className="text-base pt-10 pb-4 text-blueg">Never miss any humour!</div>

          <div className="text-base pb-10 text-blueg font-light">
            One of the funniest and most prolific stand-up acts in the country, <span className="font-extrabold">Gaurav Kapoor's Vlogs </span>have created a fan base of their own. <span className="font-extrabold">1M+</span> on YouTube,{" "}
            <span className="font-extrabold">400K+</span> on Insta, an <span className="font-extrabold">IMDB 8.2</span> rated special on Amazon Prime, Gaurav has proven year after year that he is funny and relatable across all formats. An avid traveler, he often feels that the
            mountains are calling but in a few days Delhi calls him back even louder.
            <div className="p-1 md:w-[200%] overflow-visible"></div>
            His massive live shows and regular VLogs are making him a household name and proving that one can truly live his art if he is brutally honest. His journalism degree and retail job experience now seem like stories from another life but Gaurav claims he still remembers
            all the keyboard shortcuts, just in case.{" "}
          </div>
        </div>
        <div className="bg-grayg  md:w-1/2 flex justify-between  md:justify-start flex-col md:items-end">
          <div className="flex md:justify-end ">
            <div className="text-[25px] font__mochiy-pop-one text-pinkg md:w-full md:text-right">Contact for more information (:</div>
          </div>
          <div className="flex flex-col md:justify-start md:pt-4 md:items-end">
            <div className="text-base py-4 md:pt-8 md: pb-px text-blueg">
              <a className="font-extrabold" href="mailto:gauravkapoorcomedy@gmail.com">
                gauravkapoorcomedy
              </a>
              @gmail.com
            </div>
            <div className="text-base py-4 md:pb-4 text-blueg font-light md:text-right  text-left  ">
              Mobile: +91
              <a className="font-extrabold" href="tel:+91 98106 66433">
                98106 66433
              </a>
            </div>
            <div className="md:pb-2 md:-mr-2">
              <FooterSocialcons />{" "}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const FooterSocialcons = (props) => {
 return (
   <>
     <div className='flex flex-row  justify-between'>
       <div className="list-inline flex list-none">
         <li className="list-inline-item mr-2 list-none">
           <a href="https://www.facebook.com/GauravKapoorComedy" target="_blank" rel="noopener noreferrer">
             <img src="/assets/facebook.svg" alt="facebook" className="w-[31px] md:w-[24px]  h-[31px] md:h-[24px] " />
           </a>
         </li>
         <li className="list-inline-item mx-2">
           <a href="https://www.instagram.com/gauravkpoor/" target="_blank" rel="noopener noreferrer">
             <img src="/assets/instagram.svg" alt="instagram" className="w-[31px] md:w-[24px]  h-[31px] md:h-[24px] " />
           </a>
         </li>
         <li className="list-inline-item mx-2">
           <a href="mailto:gauravkapoorcomedy@gmail.com" target="_blank" rel="noopener noreferrer">
             <img src="/assets/gmail.svg" alt="gmail" className="w-[31px] md:w-[24px]  h-[31px] md:h-[24px] " />
           </a>
         </li>
       </div>{" "}
       <div className='list-inline flex list-none md:hidden'>

       <li className="list-inline-item mx-2 md:-mr-px">
         <a href="#header">
           <img src="/assets/back-to-top.svg" className="w-8 h-8" alt="back to top" />
         </a>
       </li>
       </div>
     </div>
   </>
 );
}