import React, { useState, useEffect } from "react";
import axios from "axios";
import useWindowDimensions from "../hooks/window-dimensions";
import ShowsSingleCard from "./shows/ShowsSingleCard";

export default function Shows() {
  const [data, setData] = useState();
  const [maxShows, setMaxShows] = useState(4);
  useEffect(() => {
    const getSheetData = (e) => {
      axios
        .get(
          "https://docs.google.com/spreadsheets/d/e/2PACX-1vRea1I5H-phqSgkL31-T_KMCh6SRjh0sMDRIibN722zWzRcsOMjtPoTQcoAT8PXIy5xFH2a7gcUvgGA/pub?gid=0&single=true&output=csv"
        )
        .then((response) => {
          console.log(response);
          // let tempData = response.data.split("\r\n");
          // let columnData = rowsData[0].split(",");
          // console.log(tempData);
          setData(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    };
    getSheetData();
  }, []);

    useEffect(() => {
      setMaxShows(() =>data ? data.split("\n").length : 4);
    }, [data]);
  return (
    <div className="md:bg-grayg">
      <div className="bg-grayg pb-28 px-10 pt-2  -mt-2 md:md:container md:mx-auto md:px-52">
        <div className="grid md:grid-cols-2 gap-3 md:gap-8 px-5 md:px-10 mx-auto">
          {data &&
            data.split("\n").map((item, index) => {
              return (
                <>
                  {index > 0 && index <= maxShows && (
                    <ShowsSingleCard
                      key={"show" + index}
                      venue={item.split(",")[0]} //this is actually DAY data
                      date={item.split(",")[1].split(" ")[0]}
                      month={item.split(",")[1].split(" ")[1]}
                      day={item.split(",")[2]}
                      time={item.split(",")[3]}
                      showLink={item.split(",")[4]}
                      year={item.split(",")[5] ?? "2022"}
                    />
                  )}
                </>
              );
            })}
        </div>
          <ViewMoreShows maxShows={maxShows} setMaxShows={setMaxShows} dataMaxLength={data ? data.split("\n").length : 4} />
      </div>
    </div>
  );
}


const ViewMoreShows = ({maxShows, setMaxShows,dataMaxLength}) => {
  var { width } = useWindowDimensions();

  return (
    <>
      <div className="flex justify-start pt-4 w-full md:w-[272px] md:pt-10 md:pl-8 ">
        {/* the scroll logic is only dependent on whether it's mobile or desktop..and it should definitely be != 4 */}
        <div onClick={() => {setMaxShows(maxShows === 4 ? dataMaxLength : 4); width > 720 ? maxShows !== 4 && window.scrollTo(0, window.scrollY - (dataMaxLength - 4) * 100) : maxShows !== 4 && window.scrollTo(0, window.scrollY - (dataMaxLength - 4) * 130);}} className="bg-blueg border-2 border-blueg text-white w-full text-center pl-[4%] pr-[4.4%] py-2 rounded-xl font-extrabold  hover:border-[#ffdb15] hover:border-2 transition-all ">
          {maxShows !== 4 ? <>View Less Shows</> : <>View All Shows</>}
          {/* <span className="  absolute pl-2 pt-[3px] ">
            <img src="/assets/arrow red.gif" className="h-4 w-4 " alt="" />
          </span> */}
        </div>
      </div>
    </>
  );
};
