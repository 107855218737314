import React from "react";
import "@splidejs/react-splide/css";
import Navbar from "./components/Navbar";
import DesktopSidebar from "./components/DesktopSidebar";
import HeroBanner from "./components/HeroBanner";
import Instagram from "./components/Instagram";
import CoverEyes from "./components/CoverEyes";
import LinksSection from "./components/LinksSection";
import TikTok from "./components/TikTok";
import Shows from "./components/Shows";
import VideoArchives from "./components/VideoArchives.jsx";
import FooterAbout from "./components/FooterAbout";
import Footer from "./components/Footer";
import BottomNav from "./components/BottomNavigation";
import BrandPromotions from './components/BrandPromotions';

export default function MyApp() {
  const [hidden, setHidden] = React.useState(true);
  return (
    <>
      <main className="z-1  text-white overflow-x-hidden bg-blueg ">
        <div id="header">
          <Navbar setHidden={setHidden} />
          <DesktopSidebar hidden={hidden} setHidden={setHidden} />
          <div onClick={() => setHidden(true)}>
            <HeroBanner />
            <Instagram />
          </div>
        </div>
        <div onClick={() => setHidden(true)}>
          <CoverEyes />
          <LinksSection />
          <TikTok />
          <Shows />
          <BrandPromotions/>
          <div id="videos">
            <VideoArchives />
          </div>
          <div className="bg-grayg">
            <div id="footer" className="md:w-7/12 md:mx-auto ">
              <FooterAbout />
              <Footer />
            </div>
          </div>
          <div className="py-8 bg-grayg"></div>
          <BottomNav />
        </div>
      </main>
    </>
  );
}

// const md:container = document.getElementById("root");
// const root = ReactDOM.createRoot(md:container);
// root.render(<MyApp />);
