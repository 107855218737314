
export default function LinksSection() {
  return (
    <div className="md:container md:mx-auto md:px-52">
      {/* <div className="text-[200%] md:text-6xl leading-tight px-10 font-extralight  my-auto font__roboto-condensed">
        High End, High Start!!
        <br />
        Stay Tuned Forever
      </div> */}
      <div className="pt-8"></div>
      <hr className="mx-auto w-4/5 md:w-1/2 md:mx-0 md:ml-10" />
      <div className="pt-8"></div>
      <div className="text-[140%] px-10 pb-10 font-extralight leading-tight font__roboto-condensed  my-auto">
        Keep your calm, Stand-up <div className="block md:hidden"></div>& Stay
        connected
      </div>
      <div className="px-10 grid grid-cols-2 md:grid-cols-4 gap-4 md:gap-11">
        <a
          href="https://www.youtube.com/c/GauravKapoor?sub_confirmation=1"
          target="_blank" rel="noopener noreferrer"
          className="border  hover:border-orangeg rounded-lg p-4  md:py-6 "
        >
          <div className="">
            <span className="text-center text-xs md:text-base font-bold rounded-xl bg-orangeg text-black px-4 py-1">
              WATCH
            </span>
          </div>
          <div className="pt-8 pb-2 md:text-lg">YouTube</div>
          <div className="py-2 text-[13.2px] md:text-base">
            <span className="font-bold">Watch</span> GK for non stop laughter...
          </div>
          <img className="pt-3" src="/assets/youtube-white.svg" alt="youtube" />
        </a>
        <a
          href="https://www.youtube.com/c/GauravKapoorVlogs?sub_confirmation=1"
          target="_blank" rel="noopener noreferrer"
          className="border  hover:border-lightblueg rounded-lg p-4  md:py-6"
        >
          <div className="">
            <span className="text-center text-xs md:text-base font-bold rounded-xl bg-lightblueg text-black px-4 py-1">
              WATCH
            </span>
          </div>
          <div className="pt-8 pb-2 md:text-lg">VLogs</div>
          <div className="py-2 text-[13.2px] md:text-base">
            <span className="font-bold">Experience</span> stand-up with GK...
          </div>
          <img className="pt-3" src="/assets/vlog-icon.svg" alt="youtube" />
        </a>
        <a
          href="https://www.primevideo.com/detail/0Q390SAK132JBFQ7Y4OYGGX2NW/ref=dvm_src_ret_in_xx_s"
          target="_blank" rel="noopener noreferrer"
          className="border rounded-lg p-4   hover:border-greeng md:py-6"
        >
          <div className="">
            <span className="text-center text-xs md:text-base font-bold rounded-xl bg-greeng text-black px-4 py-1">
              WATCH
            </span>
          </div>
          <div className="pt-8 pb-2 md:text-lg">Amazon Prime</div>
          <div className="py-2 text-[13.2px] md:text-base">
            <span className="font-bold">Videos</span> with Non stop comedy...
          </div>
          <img className="pt-3" src="/assets/amazon-icon.svg" alt="youtube" />
        </a>
        <a
          href="https://linktr.ee/gauravkpoor"
          target="_blank" rel="noopener noreferrer"
          className="border  hover:border-yellowg  rounded-lg p-4  md:py-6"
        >
          <div className="">
            <span className="text-center text-xs md:text-base font-bold rounded-xl bg-yellowg text-black px-4 py-1">
              WATCH
            </span>
          </div>
          <div className="pt-8 pb-2 md:text-lg">Stand Up</div>
          <div className="py-2 text-[13.2px] md:text-base">
            <span className="font-bold">Waiting</span> for you at the Events...
          </div>
          <img className="pt-3" src="/assets/mic-icon.svg" alt="youtube" />
        </a>
      </div>
    </div>
  );
}