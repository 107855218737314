
export default function ShowsSingleCard({ date, month, year, day, time, venue, showLink }) {
  return (
    <div className="flex justify-around">
      <div className="w-1/2 md:w-2/5">
        <div className="flex flex-col justify-around h-full">
          <div className="text-blueg text-[22px] usm:text-[28px] md:text-[28px] 2xl:text-[38px] leading-none font-light font__roboto-condensed pt-2">
            {date} <br />
            {month}, {year}
          </div>
          <div className="pt-1">
            <div className="text-2xl font__roboto-condensed">
              <a
                href="https://linktr.ee/gauravkpoor"
                target="_blank" rel="noopener noreferrer"
                className="text-white font-extrabold text-xs  md:text-base bg-pinkg py-[6px] px-4 rounded-2xl"
              >
                {venue}
              </a>
            </div>
          </div>
        </div>
      </div>
      <a href={showLink} target="_blank" rel="noopener noreferrer" className="md:w-2/5">
        <div className="flex  text-center">
          <div className="border border-pinkg rounded-lg">
            <div className="text-xl  md:text-[24px]font__roboto-condensed leading-none">
              <div
                href={showLink}
                className="text-pinkg font-extrabold pt-4 px-4 rounded-xl"
              >
                {day}
              </div>
              <div
                href={showLink}
                className="text-pinkg font-extralight py-2 px-4 rounded-xl"
              >
                at {time}
              </div>
            </div>
            <div className="text-[16px] md:text-[23px] font__roboto-condensed w-full py-3 bg-lightblueg px-4 rounded-b-lg">
              <div className="text-white w-full ">BOOK SHOW</div>
            </div>
          </div>
        </div>
      </a>
      <div className="pt-10" />
    </div>
  );
}