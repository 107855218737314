import { motion, animate, useMotionValue } from "framer-motion";

export default function Navbar({ setHidden }) {
  const opacity = useMotionValue(0);
  return (
    <div className="h-16  w-full text-white fixed z-40 flex items-center justify-around">
      <motion.div onMouseEnter={() => animate(opacity, 0)} className="text-[27px] pl-10 md:pl-12 font__mochiy-pop-one">
        Gaurav Kapoor
      </motion.div>
      <div className="flex flex-row items-center gap-2">
        <div className='hidden md:block'>

        <AllShowsButton />
        </div>
        <div className="text-[27px] hidden md:block cursor-pointer  " onClick={() => setHidden(false)}>
          <img src="/assets/hamburger.svg" className="w-11 h-11  mr-2 ml-6 mt-2" alt="Open Menu" />
        </div>
        <div className="flex items-end justify-end md:pl-2 md:pt-2">
          <a href="#shows">
            <img src="/assets/gk-tag.png" className="h-[46px] w-[46px] md:h-[55px] md:w-[55px] " alt="" />
          </a>
        </div>
      </div>
    </div>
  );
}



const AllShowsButton = () => {
  return (
    <>
      <div className="flex justify-start pt-4  w-full md:mx-2">
        <a href="#shows" className="bg-pinkg border-2 border-pinkg text-white w-full text-center pl-[4%] pr-[4.4%] py-2 rounded-xl font-extrabold  hover:border-[#ffdb15] hover:border-2 transition-all ">
          View All Shows
        </a>
      </div>
    </>
  );
};