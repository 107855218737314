import { AnimatePresence, motion } from "framer-motion";
import useWindowDimensions from "../hooks/window-dimensions";
import {useState,useEffect } from "react";
import axios from 'axios';
import ShowsDoubleCard from './shows/ShowsDoubleCard';

const variants = {
  open: { x: "-40%", marginLeft: "40%" },
  closed: { x: "0%" , marginLeft: "0%"},
};


export default  function HeroBanner() {
  const { width } = useWindowDimensions();

  return (
    <>
      {/* outside flex only for desktop */}

      <img
        src="/assets/gaurav-hero.webp"
        alt="Gaurav Kapoor Sitting on a table with a mic in one hand and humour in the other and people saying 'One More Joke'"
        className="absolute left-0 top-8 z-30 w-[575px] container md:-ml-[-4%]  xl:ml-[-4%] lg:ml-[-4%]  2xl:ml-[4%]  sm:ml-[4%] h-[779px] hidden md:block -pt-0 "
      />
      <div className="flex flex-col-reverse md:flex-row md:min-h-screen">
        <div className="md:hidden">
          <AnimatePresence>
            <motion.div initial={{ y: 400 }} animate={{ y: 0 }} transition={{ type: "spring", stiffness: 100, duration: 4 }} className="bg-blueg pb-4 md:pb-0 md:pt-10 md:container mx-auto ">
              <div className="flex justify-center overflow-hidden w-[150%] -ml-[40%]">
                {/* only for mobile */}
                {width < 768 && <img src="/assets/gaurav-hero.webp" alt="Gaurav Kapoor Sitting on a table with a mic in one hand and humour in the other and people saying 'One More Joke'" className="block md:hidden -mt-8 -mb-2  h-[769px] w-[550px] overflow-hidden pl-4" />}
              </div>
            </motion.div>
          </AnimatePresence>
        </div>

        <div className="-ml-8 xl:pl-[18%] md:pl-[12%] ">
          <HeroBannerShowsBooking />
        </div>

      </div>
    </>
  );
}



 function HeroBannerShowsBooking() {
   const [data, setData] = useState();
  const { width } = useWindowDimensions();

    const [isOpen, setIsOpen] = useState(false);

    // useEffect(() => {
    //   const interval = setInterval(() => {
    //     setIsOpen((isOpen) => !isOpen);
    //   }, 12000);
    //   return () => clearInterval(interval);
    // }, []);


   useEffect(() => {
     const getSheetData = (e) => {
       axios
         .get("https://docs.google.com/spreadsheets/d/e/2PACX-1vRea1I5H-phqSgkL31-T_KMCh6SRjh0sMDRIibN722zWzRcsOMjtPoTQcoAT8PXIy5xFH2a7gcUvgGA/pub?gid=0&single=true&output=csv")
         .then((response) => {
           console.log(response);
           // let tempData = response.data.split("\r\n");
           // let columnData = rowsData[0].split(",");
           // console.log(tempData);
           setData(response.data);
         })
         .catch((error) => {
           console.log(error);
         });
     };
     getSheetData();
   }, []);
   return (
     <div className=" mt-[101px] ">
       {/* 230*3+20*3 */}
       {/* 380*3+200*3 */}
       {/* => 1140+80*3  .. padding should be double of number specified*/}
         <div className="bg-blueg  pt-2  md:container md:mx-auto md:px-52 overflow-scroll scrollbar-hide">

       <motion.div
         animate={isOpen ? "open" : "closed"}
        //  className='ml-[40%]'
        //  variants={variants}
         //    whileHover={{ scale: 1 }}
         //    whileTap={{ scale: 0.9 }}
         transition={{ duration: 10 }}
         whileHover={() => setIsOpen(false)}
         onMouseEnter={() => setIsOpen(false)}
         onTouchEnd={() => setIsOpen(true)}
         onMouseLeave={() => setIsOpen(true)}
       >
           <div className="grid grid-flow-col grid-cols-2 grid-rows-2 md:grid-rows-3  gap-x-0 gap-y-3  md:gap-x-9 px-5 md:px-[90px] w-[750px] md:w-[1380px] mx-6">
             {data &&
               data.split("\n").map((item, index) => {
                 return (
                   <>
                     {/* the index should not be more than 6 on mobile devices  */}
                     {index > 0 && index <= 6 && width < 768 && (
                       <ShowsDoubleCard
                         key={"show" + index}
                         venue={item.split(",")[0]} //this is actually DAY data
                         date={item.split(",")[1].split(" ")[0]}
                         month={item.split(",")[1].split(" ")[1]}
                         day={item.split(",")[2]}
                         time={item.split(",")[3]}
                         showLink={item.split(",")[4]}
                         year={item.split(",")[5] ?? "2023"}
                       />
                     )}
                     {index > 0 && index <= 9 && width > 768 && (
                       <ShowsDoubleCard
                         key={"show" + index}
                         venue={item.split(",")[0]} //this is actually DAY data
                         date={item.split(",")[1].split(" ")[0]}
                         month={item.split(",")[1].split(" ")[1]}
                         day={item.split(",")[2]}
                         time={item.split(",")[3]}
                         showLink={item.split(",")[4]}
                         year={item.split(",")[5] ?? "2023"}
                       />
                     )}
                   </>
                 );
               })}
             {/* <div className="md:w-[1800px] md:h-px"></div> */}
           </div>
       </motion.div>
         </div>
     </div>
   );
 }
