import { Splide } from "@splidejs/react-splide";
import { SplideSlide } from "@splidejs/react-splide";
import useWindowDimensions from "../hooks/window-dimensions";
import { useState, useEffect } from "react";
import axios from "axios";
export default function VideoArchives() {
  // var isMobile = window.innerWidth < 768;
  var { width } = useWindowDimensions();
  // React.useEffect(() => {
  //   isMobile = window.innerWidth < 768;
  // }, []);
  const [slides, setSlides] = useState([]);
  const [slides2, setSlides2] = useState([]);
  useEffect(() => {
    const getSheetData = (e) => {
      axios
        .get(
          // this is the VIDEOS  sheet excel
          // "https://timecommit.com/api/v1/venue/633e72de74f2a8d30bab5017/fgeUWAQ/633e731474f2a8d30bab5018/2",
          "https://docs.google.com/spreadsheets/d/e/2PACX-1vRea1I5H-phqSgkL31-T_KMCh6SRjh0sMDRIibN722zWzRcsOMjtPoTQcoAT8PXIy5xFH2a7gcUvgGA/pub?gid=1390478751&single=true&output=csv"
        )
        .then((response) => {
          var data = response.data.split("\n");
          var slide1temp = [];
          var slide2temp = [];
          data.map((item, index) => {
            if (index > 1) {
              var arrayElement = item.split(",");
              arrayElement[0] !== "" && slide1temp.push(arrayElement[0]);
              arrayElement[1] !== "" && slide2temp.push(arrayElement[1]);
            }
          });
          setSlides(slide1temp);
          setSlides2(slide2temp);
        })
        .catch((error) => {
          console.log(error);
        });
    };
    getSheetData();
  }, []);

  // const slides = [
  //   "https://www.youtube.com/embed/E6K396ynLYc",
  //   "https://www.youtube.com/embed/J38ZBIvLank",
  //   "https://www.youtube.com/embed/XDlyS4N__3o",
  //   "https://www.youtube.com/embed/nQi-rfY2Vrk",
  //   "https://www.youtube.com/embed/JUl_ect0kns",
  // ];
  // const slides2 = [
  //   "https://www.youtube.com/embed/2ZKcbsGKFHA",
  //   "https://www.youtube.com/embed/8ZX86pVN-l4",
  //   "https://www.youtube.com/embed/K2nspg4_CVk",
  //   "https://www.youtube.com/embed/LD92a_JbSs8",
  //   "https://www.youtube.com/embed/7LYWENuKiPM",
  //   "https://www.youtube.com/embed/SxfQ9NxCSdE",
  // ];
  // const swiper = useSwiper("swiper-0");

  return (
    <div className="">
      <div className="bg-pinkg">
        <div className="w-10/12 md:w-7/12 py-10 md:px-10  mx-auto text-center md:text-start md:pt-32">
          <div className="text-white font-light text-3xl pb-5 md:pb-10 md:text-[52px]  leading-normal">
            Video Archives <br />
            Never miss any humour!
          </div>

          {/* start */}
          <div className="px-0 md:px-0 relative" id="about">
            {/* <div className=""> */}

            <div className="p-3" />
            <div className="">
              <Splide
                aria-label="Gaurav Kapoor Videos"
                options={{
                  type: "slide",
                  rewind: true,
                  perPage: width < 768 ? 1 : 2,
                  perMove: 1,
                  pagination: false,
                  gap: "1rem",
                }}
              >
                {slides.map((slide, index) => (
                  <SplideSlide key={index}>
                    <div className=" py-2 md:py-2 w-full">
                      <div className="">
                        <div className=" mx-auto">
                          <iframe className="w-full aspect-video" src={slide} title={slide} frameborder="0" playsinine="1" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture " allowfullscreen></iframe>
                        </div>
                      </div>
                    </div>
                  </SplideSlide>
                ))}
              </Splide>
              <Splide
                aria-label="Gaurav Kapoor Videos"
                options={{
                  type: "slide",
                  rewind: true,
                  perPage: width < 768 ? 1 : 2,
                  perMove: 1,
                  pagination: false,
                  gap: "1rem",
                }}
              >
                {slides2.map((slide, index) => (
                  <SplideSlide key={index}>
                    <div className=" py-2 md:py-2 w-full">
                      <div className="">
                        <div className=" mx-auto">
                          <iframe className="w-full aspect-video" src={slide} title={slide} frameborder="0" playsinine="1" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture " allowfullscreen></iframe>
                        </div>
                      </div>
                    </div>
                  </SplideSlide>
                ))}
              </Splide>{" "}
              {/* <Swiper
                spaceBetween={50}
                modules={[Navigation, Controller]}
                slidesPerView={isMobile ? 1 : 2}
                onSlideChange={() => console.log("slide change")}
                className="swiper-0"
                loop={true}
                navigation
              >
                {slides.map((slide, index) => (

                  <SwiperSlide key={index}>
                    <div className=" py-2 md:py-2 w-full">
                      <div className="">
                        <div className=" mx-auto">
                          <iframe
                            className="w-full aspect-video"
                            src={slide}
                            title="HR Friday and Emails"
                            frameborder="0"
                            playsinine="1"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture "
                            allowfullscreen
                          ></iframe>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper> */}
              <div classname="p-96 " />
            </div>

            {/* ... */}

            <div className="p-3" />
            <div className="">
              {/* <Swiper
                spaceBetween={50}
                modules={[Navigation, Controller]}
                slidesPerView={isMobile ? 1 : 2}
                onSlideChange={() => console.log("slide change")}
                onSwiper={(swiper) => console.log(swiper)}
                className="swiper-0"
                loop={true}
                navigation
              >
                {slides2.map((slide, index) => (
                  <SwiperSlide key={index}>
                    <div className=" py-2 md:py-2 w-full">
                      <div className="">
                        <div className=" mx-auto">
                          <iframe
                            className="w-full aspect-video"
                            src={slide}
                            title="HR Friday and Emails"
                            frameborder="0"
                            playsinine="1"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture "
                            allowfullscreen
                          ></iframe>
                        </div>
                      </div>
                    </div>
                    ,
                  </SwiperSlide>
                ))}
              </Swiper> */}
              <div classname="p-96" />
            </div>
          </div>

          {/* end */}
        </div>
        <div className="flex justify-center pb-10 md:hidden">
          <div className="rounded-[32px] bg-lightblueg text-white py-3 pt-4  px-[90px]">
            <a href="https://www.youtube.com/c/GauravKapoor?sub_confirmation=1" target="_blank" rel="noopener noreferrer" className="text-[22px]">
              View More
            </a>
          </div>
        </div>
      </div>
      {/* only for desktop 6rem padding */}
      <div className="hidden md:flex justify-center pb-24 bg-pinkg"></div>
      <div className="md:hidden justify-center pb-2 bg-pinkg"></div>
      <div className="w-[120%] -mx-4">
        <img src="/assets/curved-pink.svg " className="w-full bg-grayg -mt-2  " alt="curve" />
      </div>
    </div>
  );
}
