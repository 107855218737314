import { Splide } from "@splidejs/react-splide";
import { SplideSlide } from "@splidejs/react-splide";
import useWindowDimensions from "../hooks/window-dimensions";
import { useState, useEffect } from "react";
import axios from "axios";
import InstagramEmbed from "react-instagram-embed";

const BrandPromotions = (props) => {
  var { width } = useWindowDimensions();

  const [slides, setSlides] = useState([]);
  useEffect(() => {
    const getSheetData = (e) => {
      axios
        .get(
          // this is the VIDEOS  sheet excel
          "https://docs.google.com/spreadsheets/d/e/2PACX-1vRea1I5H-phqSgkL31-T_KMCh6SRjh0sMDRIibN722zWzRcsOMjtPoTQcoAT8PXIy5xFH2a7gcUvgGA/pub?gid=1390478751&single=true&output=csv"
        )
        .then((response) => {
          var data = response.data.split("\n");
          var slide1temp = [];
          // eslint-disable-next-line array-callback-return
          data.map((item, index) => {
            if (index > 0) {
              var arrayElement = item.split(",");
              console.log(typeof arrayElement[2]);
              console.log(arrayElement[2].length);
              arrayElement[2] !== "" && arrayElement[2] !== " " && arrayElement[2].length > 3 && slide1temp.push(arrayElement[2]);
            }
          });
          setSlides(slide1temp);
        })
        .catch((error) => {
          console.log(error);
        });
    };
    getSheetData();
  }, []);
  return (
    <>
      <div className="w-[120%] -mx-4">
      <img src="/assets/curved-blue.svg " className="w-full  -mt-2" alt="curve" />
    </div>
      <div className="w-10/12 md:w-7/12 py-10 md:px-10  mx-auto text-center md:text-start md:pt-32">
        {/* <div className="md:hidden justify-center pb-2 bg-pinkg"></div> */}
        <div className="bg-white h-px w-full"></div>
        <div className="flex  w-full justify-around md:justify-start items-center py-4">
          <div className="">
            <img src="/assets/gk-tag.png" className="h-[47px] md:h-[88px]" alt="" />
          </div>

          <div className="text-2xl md:text-5xl md:pl-10">+</div>
          <div className="text-[32px] xl:text-[51px] font-extralight text-start leading-none md:pl-10 relative">
            <div className="absolute -top-4 -left-8 md:left-0">
              <lottie-player src="/38319.json" background="transparent" speed={1} style={{ width: 229, height: 104 }}  loop autoPlay />
            </div>
            <h1>
              BRAND-
              <br className="md:hidden" />
              COLLABORATION
            </h1>
          </div>
        </div>
        <div className="bg-white h-px w-full"></div>
        <div className="text-start pb-8 md:pb-10 md:pt-11">
          {/* <h1 className="text-base md:text-xl pt-6"># HUMOUR-IN-HAND</h1> */}
          {/* <p className="text-base md:text-xl">We bring to you GK's experience with top brand on YouTube videos and IG Reels.</p> */}
        </div>
        <div className="md:pb-32">
          <Splide
            aria-label="Gaurav Kapoor Videos"
            options={{
              type: "slide",
              rewind: true,
              perPage: width < 768 ? 1 : 2,
              perMove: 1,
              pagination: false,
              gap: "1rem",
            }}
          >
            {slides.map((slide, index) => (
              <SplideSlide key={index}>
                <div className=" py-2 md:py-2 w-full">
                  <div className="">
                    <div className=" mx-auto">
                      {slide.includes("youtube") ? (
                        <iframe className="w-full aspect-video" src={slide} title={slide} frameborder="0" playsinine="1" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture " allowfullscreen></iframe>
                      ) : (
                        <InstagramEmbed url="https://instagr.am/p/Zw9o4/" clientAccessToken="123|456" maxWidth={320} hideCaption={false} containerTagName="div" protocol="" injectScript onLoading={() => {}} onSuccess={() => {}} onAfterRender={() => {}} onFailure={() => {}} />
                      )}
                    </div>
                  </div>
                </div>
              </SplideSlide>
            ))}
          </Splide>
        </div>
      </div>
    </>
  );
};
export default BrandPromotions;
