import useWindowDimensions from "../hooks/window-dimensions";
import { useState } from "react";

export default function Instagram() {
  const { width } = useWindowDimensions();
//   const [hoverBook, setHoverBook] = useState(false);
  return (
    <div className="md:container mx-auto flex md:relative md:top-40 md:pb-20">
      <div className="hidden md:block w-1/2 justify-center ">
        <div className="flex flex-col justify-center md:absolute w-1/3 md:left-48">
          <h1 className="pt-5 pb-10 text-5xl">Get. Set. Laugh.</h1>
          <p className="text-xl">GK could easily be described as the ideal Dilliwala comedian; affable, loveable and hilarious.</p>
          <p className=" text-xl py-3">His observational, dry, often unanticipated comedy has seen him take to stages all over the country.</p>
          <div className="flex justify-start pt-8">
            <a
              href="#shows"
              className="bg-pinkg border-2 border-pinkg text-white text-center pl-[67px] pr-[78px] py-2 rounded-xl font-extrabold  hover:border-[#ffdb15] hover:border-2 transition-all "
            >
              Book Your Show
              <span className="  absolute pl-2 pt-[3px] ">
                {/* {hoverBook ?  */}
                <img src="/assets/arrow red.gif" className="h-4 w-4 " alt="" />
                {/* //  <img src="/assets/arrow red.gif" className="h-4 w-4  " alt="" />} */}
              </span>
            </a>
          </div>
        </div>
      </div>
      <div className="md:container mx-auto my-10 md:my-0 sm:px-20 flex justify-center w-[85%] md:w-4/5 md:relative md:-top-24 md:-right-24 ">
        {width > 768 && <img src="/assets/cone-left-insta.svg" className="absolute left-[11.5%] top-60 h-[20%] " alt="" srcSet="" />}
        {width > 768 && <img src="/assets/insta-arrow-right.svg" className="absolute right-[8.5%] top-[4.5%] h-[60%] hidden md:block" alt="" srcSet="" />}
        <div className="rounded overflow-hidden w-full md:w-1/2 mx-5 md:mx-0 lg:mx-0">
          <div className="w-full flex justify-between p-3 relative">
            <div className="flex">
              <div className="rounded-full h-8 w-8 bg-gray-500 flex items-center justify-center overflow-hidden">
                <img src="/assets/instagram_profile.png" alt="profilepic" />
              </div>
              <span className="pt-1 ml-2 font-bold text-sm text-white flex">
                gauravkpoor <span className="pl-0.5"></span>
                <img src="https://img.icons8.com/color/344/verified-badge.png" alt="" className="h-5 w-5" />
              </span>
            </div>
            <a href="https://instagram.com/gauravkpoor" target={"_blank"} rel="noopener noreferrer" className="px-2  cursor-pointer rounded">
              <img src="/assets/follow-button.svg" alt="follow" className="hidden md:block " />
            </a>
          </div>

          {/* <img
            src="/assets/Path 237.svg"
            alt="path 20"
            className="absolute left-40"
          /> */}
          <img className="w-11/12 mx-auto bg-cover" src="/assets/gk-instagram.png" alt="Book Show" />
          <div className="flex justify-between p-3">
            <div className="flex">
              <img src="/assets/like.svg" alt="like" className="w-5 h-5 mx-2" />
              <img src="/assets/comment.svg" alt="like" className="w-5 h-5 mx-2" />
              <img src="/assets/share.svg" alt="like" className="w-5 h-5 mx-2" />
            </div>
            <div>
              <img src="/assets/bookmark.svg" alt="like" className="w-5 h-5 mx-2" />
            </div>
          </div>
          <div className="px-4 pb-2">
            <div className="pt-2">
              <i className="far fa-heart cursor-pointer"></i>
              <span className="text-sm text-white font-medium">10,785 Likes</span>
            </div>
            <div className="pt-1">
              {/* <div className="mb-2 text-sm text-white">After an hour in his company, you’re chilled out and smiling.</div> */}
            </div>
            {/* this button is visible only on desktop */}
            <div className="">
             <BookShowButton />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}


const BookShowButton = () => {
  return (
    <>
      <div className="flex justify-start pt-4 md:hidden w-full">
        <a
          href="#shows"
          className="bg-pinkg border-2 border-pinkg text-white w-full text-center pl-[4%] pr-[4.4%] py-2 rounded-xl font-extrabold  hover:border-[#ffdb15] hover:border-2 transition-all "
        >
          Book Your Show
          <span className="  absolute pl-2 pt-[3px] ">
            {/* {hoverBook ?  */}
            <img src="/assets/arrow red.gif" className="h-4 w-4 " alt="" />
            {/* //  <img src="/assets/arrow red.gif" className="h-4 w-4  " alt="" />} */}
          </span>
        </a>
      </div>
    </>
  );
};