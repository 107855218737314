import { AnimatePresence, motion } from "framer-motion";


export default function DesktopSidebar({ hidden, setHidden }) {
  return (
    <>
      <AnimatePresence>
        {!hidden && (
          <motion.div
            initial={{ opacity: 0, width: "10%" }}
            animate={{ opacity: 1, width: "25%" }}
            exit={{ opacity: 0, width: 10 }}
            transition={{ type: "spring", stiffness: 100 }}
            className="hidden md:block fixed right-0 top-0 h-screen w-1/4 bg-white z-40"
          >
            <div className="flex p-10 w-full  justify-between">
              <div className="flex ">
                <img src="/assets/gaurav-face.svg" alt="" srcset="" />
                <div className="text-[25px] pl-2 font__mochiy-pop-one text-blueg pt-2">
                  GK
                </div>
              </div>
              <div className="">
                <div
                  className="text-[45px] text-blueg font-bold hover:text-pinkg cursor-pointer"
                  onClick={() => setHidden(true)}
                >
                  &times;
                </div>
              </div>
            </div>
            {/* links */}
            {[
              { text: "About", link: "#explore" },
              { text: "Shows", link: "#shows" },
              { text: "Videos", link: "#videos" },
              { text: "Contact", link: "#contact" },
            ].map((item, index) => {
              return (
                <a href={item.link} className="flex p-10 py-2" key={index}>
                  <div className="text-[60px]  text-blueg hover:text-pinkg">
                    {item.text}
                  </div>
                </a>
              );
            })}
            <div className="my-auto">
              <ul className="list-inline flex justify-start py-10 px-10 w-4/5">
                <li className="list-inline-item mr-2">
                  <a href="https://www.facebook.com/GauravKapoorComedy" target="_blank" rel="noopener noreferrer">
                    <img
                      src="/assets/facebook.svg"
                      alt="facebook"
                      className="w-8 h-8 mr-4"
                    />
                  </a>
                </li>
                {/* <li className="list-inline-item mx-2">
                  <img
                    src="/assets/twitter.svg"
                    alt="twitter"
                    className="w-8 h-8 mx-4"
                  />
                </li> */}
                <li className="list-inline-item mx-2">
                  <a href="https://www.instagram.com/gauravkpoor/" target="_blank" rel="noopener noreferrer">
                    <img
                      src="/assets/instagram.svg"
                      alt="instagram"
                      className="w-8 h-8 mx-4"
                    />
                  </a>
                </li>
                <li className="list-inline-item mx-2">
                  <a href="mailto:gauravkapoorcomedy@gmail.com" target="_blank" rel="noopener noreferrer">
                    <img
                      src="/assets/gmail.svg"
                      alt="gmail"
                      className="w-8 h-8 mx-4"
                    />
                  </a>
                </li>
              </ul>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
}