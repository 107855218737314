
export default function ShowsDoubleCard({ date, month, year, day, time, venue, showLink }) {
  return (
    <div className="flex justify-around w-[201px] h-[125px] md:w-[280px] md:h-[177px]">
      <div className="w-[110px] md:w-[213px]">
        <div className="flex flex-col justify-around items-start h-full">
          <div className="text-white text-[32px] usm:text-[28px] md:text-[38px] 2xl:text-[38px] leading-none font-light font__roboto-condensed md:pt-2">
            {date} <br />
            {month},<br className="md:hidden" /> {year}
          </div>
          <div className="flex justify-center">
            <div className=" font__roboto-condensed ">
              <a className="text-white font-extrabold text-xs m-0  md:text-base bg-g py-[6px] px-4 rounded-2xl bg-pinkg" href="https://linktr.ee/gauravkpoor" target="_blank" rel="noopener noreferrer">
                {venue}
              </a>
            </div>
          </div>
        </div>
      </div>
      <a href={showLink} target="_blank" rel="noopener noreferrer" className="w-1/2 md:w-2/5">
        <div className="flex  items-end text-center w-[120px] md:w-[172px] md:h-[172px] ">
          <div className="border border-orangeg rounded-lg">
            <div className="usm:text-[18px] text-xl  md:text-[28px] leading-none">
              <div href={showLink} className="text-orangeg font-extrabold pt-4 px-4 rounded-xl">
                {day}
              </div>
              <div href={showLink} className="text-orangeg font-extralight py-2 px-4 rounded-xl">
                at {time}
              </div>
            </div>
            <div className="text-[16px] md:text-[23px] font__roboto-condensed w-full py-3 bg-lightblueg px-4 rounded-b-lg">
              <div className="text-white w-full ">BOOK SHOW</div>
            </div>
          </div>
        </div>
      </a>
      <div className="pt-10" />
    </div>
  );
}