
export default  function Footer() {
  return (
    <main className="z-1 pb-10 md:pb-auto">
      <footer className="text-center bg-grayg pt-2 -mt-2" id="contact">
        <div className="md:container mr-auto px-10 text-white pb-4 py-lg-5">
          <div className="flex flex-col md:flex-row md:justify-between md:items-center">
            <p className="mb-0 text-pinkg text-base pt-6 text-left">© 2023 Gaurav Kapoor. All Rights Reserved.</p>

            <a href="https://graphity.io" target="_blank" rel="noreferrer noopener" className="mb-0 text-[#989898] md:pt-6 text-base text-left">
              Powered by Graphity.io
            </a>
          </div>
        </div>
      </footer>
    </main>
  );
}